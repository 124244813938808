<template>
  <div class="container-fluid mx-0 px-0  ">
<div class="alert alert-warning alert-dismissible  covidBanner py-2" role="alert">
<span class="">Coming to visit us? Check out our <b>FAQ's</b>/ Website is under development.</span>
  <button type="button" class="btn-close btn-sm" data-bs-dismiss="alert" aria-label="Close" ></button>
</div>
  </div>
</template>

<script>
export default {
  name: "AlertFaqs",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.covidBanner {

  background: #0057B7;

 border-color: none !important;
  font-size: 12px;
  font-family: "Averta", Verdana, sans-serif;
  color: #ffffff;


}
.alert-warning {

  border-color: none !important;

}
.alert {
  position: relative;
  padding: 1rem 1rem;
    padding-right: 1rem;
  margin-bottom: 0rem !important;
border-bottom: 1px solid rgba(255, 255, 255, 0.2);
border-top:  0rem !important;
border-left: 0rem !important;
border-right: 0rem !important;
  border-radius: 0 !important;
  text-align: center;
   transition: 0s ease-in-out;

}
.btn-close {
  box-sizing: content-box;
  width: auto !important;
  height: auto !important;
  // padding: .25em .25em;
  // color: rgb(63, 10, 10) !important;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 20' fill='white'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: 1;
  font-size:10px;
  margin-right:10px

}

</style>
