export default [
  {
    path: '',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      hideNavbar: true,
      hideFooter: true,
      hideAlert: true,
      breadcrumb: 'Home',
    }
  },


  {
    path: "/mudhahotel/:id",
    name: "MudhaHotel",
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
    import(/* webpackChunkName: "mudhahotel" */ "../views/MudhaHotel.vue"),
    meta: {
      breadcrumb: {
        label: 'Mudha Hotel',
        parent: 'MudhaHotelInSomaliland'
      },
    }

  },

  {
    path: "/mudhahotelssomaliland",
    name: "MudhaHotelInSomaliland",
    component: () =>
    import(/* webpackChunkName: "mudhahotelssomaliland" */ "../views/MudhaHotelsSomaliland.vue"),
    meta: {
      breadcrumb: {
        label: 'Somaliland Hotels',
        parent: 'Home'
      },
    },


  },





]

