<template>
  <div class="container text-light myfix">
    <div class="row g-3">
      <div class="col-lg-6 col-12">
        <div class="col-megamenu">
          <h6 class="title mt-3">Our Hotels</h6>
          <div
            class="py-1 bhover text-nowrap"
            v-for="hotel in hotels"
            :key="hotel.id"
            :hotel="hotel"
          >
            <img
              class="image-size"
              :src="require(`@/assets/img/hotels/${hotel.headerimg[0]}.jpg`)"
            />

            <router-link
              :to="`/mudhahotel/${hotel.id}`"
              class="fromLeft text-white ms-2"
              @click.native="scrollToTop"
            >
              <span class="loc_font "
                > {{ hotel.location.city }}
                {{ hotel.location.country }}</span
              ><br
            /></router-link>
          </div>
        </div>
        <!-- col-megamenu.// -->
      </div>
      <!-- end col-3 -->
      <div class="col-lg-6 col-12 border-megaheader">
        <div class="col-megamenu">
          <h6 class="title mt-3 ms-3">Explore Somaliland</h6>
          <ul class="list-unstyled">
            <img
              alt="Ominco Group LTD (UK)"
              class="img-fluid p-3"
              src="@/assets/images/somaliland.jpg"
            />
          </ul>
        </div>
        <!-- col-megamenu.// -->
      </div>
      <!-- end col-3 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "hotel",
  computed: {
    hotels() {
      return this.$store.state.hotels;
    },
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },

  data() {
    return {
      hotel: this.$store.getters.hotel(this.$route.params.id),
    };
  },
};
</script>

<style scoped lang="scss">
.loc_font {
  font-size: 13px;
}
.title {
  font-size: 15px;
  font-weight: bold;
}
.border-megaheader {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.myfix {
  background-color: #0057b7;
}
.text-mega-custom {
  line-height: 22px;
  font-size: 13px;
  font-weight: 600;
  color: rgb(230, 225, 225);
}
.text-mega-custom1 {
  line-height: 22px;
  font-size: 12px;
  font-weight: 600;
  color: rgb(230, 225, 225);
}
.text-mega-custom2 {
  line-height: 22px;
  font-size: 12px;
  font-weight: 400;
  color: rgb(230, 225, 225, 0.8);
}
.bhover:hover {
  //   transform: scale(1.005);
}
.bhover {
  font-size: 14px;
  cursor: pointer;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-size {
  width: 76px;
  height: 76px;
}
.border-right-custom {
  border-right: 0.5px solid rgb(230, 225, 225, 0.3);
}
.card {
  border: none !important;
}
.card-text {
  font-size: 13px;
  font-weight: 500;
}
a {
  display: inline-block;
  text-decoration: none;
}
a:after {
  display: block;
  content: "";

  transform: scaleX(0);
  transition: all 0.3s ease 0s;
}
a:hover:after {
  transform: scaleX(1);
}

a.fromLeft:after {
  transform-origin: 0% 50%;
  border-bottom: solid 1.5px rgb(221, 219, 219);
}
</style>