<template>  <div>
  <AlertFaqs v-if="!$route.meta.hideAllert"/>
 <HeaderHome  v-if="!$route.meta.hideNavbar"/>

  <div class="container-fluid  m-0 p-0 bg-white" id="app">

 <router-view :key="$route.fullPath"> </router-view>

   <Footer v-if="!$route.meta.hideFooter"/>

  </div>

  </div>
</template>
<script>
// @ is an alias to /src
  import HeaderHome from "@/components/Header/MegaMenu/HeaderHome.vue";
import AlertFaqs from "@/components/Header/AlertFaqs.vue";
import Footer from "@/components/Footer/Footer.vue";


export default {
  components: {
AlertFaqs,
     HeaderHome,
       Footer,



  },
};
</script>
<style lang="scss">
#app {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;



}


#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
