export default {
  hotels: [
    {
      id: 3450,
      name: "Mudha Hotel",
      title:"Mudha_Hotel_Downtown_Hargeisa",
      location: {
      country: "Somaliland",
      city: "Jigjiga Yar,  Hargeisa",
      addr: "Mudha Hotel Bldg, Downtown, Hargeisa, Somaliland. P.O.Box: 41519 ",
      subway_access: true,
      distance_from_center: 0.6,
      coordinates: {lat: "9.571958554347091",long: "44.03522649726411"},
      },
      phone: "+971-42512418",
      fax: "+971-42512418",
      mail: "info@mudhahotels.com",
      bed_sizes: ["king","queen","normal"],
      included: ["breakfast","dinner","all-in","none"],
      pricing:{
        bed:
          {
            king: 265,
            queen: 200,
            normal: 150
          },
        },
      rating: {cleanliness: 4,staff: 4,comfort: 4,service: 4},
      ratingnumber: "4.5",
      ratingnumberdesc: "246 ratings & 56 reviews",
      facilities: ["Restaurant","Breakfast","Room Service","Bar","Free Wifi","Accessible","Gym","Tea/coffee","City View","Free Parking"],
      desc: "Test Description Test Test offers luxury a ccommodations in the heart of Paris, 271 m from Bonne Nouvelle Metro Station. You can relax with a drink from the on-site bar. \nHôtel l'Echiquier Opéra Paris - MGallery features spacious guest rooms equipped with a private bathroom, satellite TV and tea and coffee making facilities. Guests have access to free Wi-Fi. \nThe hotel offers you the opportunity to enjoy the Paris cultural scene with its close proximity to some of the main tourist attractions, such as Opéra Garnier, Montmartre, Georges Pompidou Center, Eiffel Tower and Louver Museum. \nThis is our guests' favorite part of Paris, according to independent reviews.",
      desccollapse: "Test Description Test Test offers luxury a cms equipped with a private free Wi-Fi. \nThe hotel offers you the opportunity to enjoy the Paris cultural scene with its close proximity to some of the main tourist attractions, such as Opéra Garnier, Montmartre, Georges Pompidou Center, Eiffel Tower and Louver Museum. \nThis is our guests' favorite part of Paris, according to independent reviews.",
      descroom: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
      img: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33'],
      headerimg: ['100'],
      rooms: {
        room: [
          {
            id: "1",
            name: '1234e Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['1', '2', '3', '4'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
          {
            id: "2",
            name: 'Superior1 Twin Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['5', '6', '7', '8'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
          {
            id: "3",
            name: 'Executive1 Double Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['9', '10', '11', '12'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
          {
            id: "4",
            name: 'Executive1 Twin Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['13', '14', '15', '16'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
          {
            id: "5",
            name: 'Executive1 Twin Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['17', '51', '52', '53'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
        ]
      }
  },
  {
    id: 3460,
    name: "Mudha Hotel",
    title:"Mudha_Hotel_Gullallah_Hargeisa",
    location: {
    country: "Somaliland - Coming Soon",
    city: "Gullallah Hargeisa",
    addr: "Mudha Hotel Bldg, Gullallah Hargeisa, Somaliland. P.O.Box: 41519 ",
    subway_access: true,
    distance_from_center: 0.6,
    coordinates: {lat: "9.563203211601053",long: "44.07692756881448"}
    },
    phone: "+971-42512418",
    fax: "+971-42512418",
    mail: "info@mudhahotels.com",
    bed_sizes: ["king","queen","normal"],
    included: ["breakfast","dinner","all-in","none"],
    pricing: {
        bed: {
          king: 265,
          queen: 200,
          normal: 150
        },
      },
    rating:{cleanliness: 5,staff: 4.5,comfort: 4.5,service: 5},
    ratingnumber: "4.5",
    ratingnumberdesc: "246 ratings & 56 reviews",
    facilities: ["Restaurant","Breakfast","Room Service","Bar","Free Wifi","Accessible","Gym","Tea/coffee","City View","Free Parking"],
    desc: "Mudha Hotel Test Description Test Test is the perfect choice for business and leisure travelers looking to stay in the heart of Dubai and to explore what the city has to offer. My team and I look forward to welcoming you to Novotel! Hotel Manager Hotel that makes every moment matter. The fdsfaddsfadf is a 4-star hotel located in dsds centre with convenient access to the city's major business hubs and leisure attractions. Our state-of-the-art meeting rooms are the perfect place for conferences, trainings and meetings of all kinds. After a day of sightseeing or business meetings, enjoy a relaxing dip in our beautiful heated outdoor pool or enjoy a pre-dinner drink at the bar. The spectacular Novotel Dubai Al Barsha towers over Sheikh Zayed Road in Dubai, close to the city's major business hubs and tourist attractions. The Mashreq Metro Station, just across the street, offers quick access to the famous Mall of the Emirates. The mall has everything from denim to diamonds. Later, chill out at Ski Dubai, warm up on Jumeirah Beach, stroll through sdfasdf, marvel at thedsfads and the World Trade Centre, and much more. Perfectly located in Dubai's Al Barsha area, Novotel Dubai Al Barsha offers easy access to all major attractions. Easy access to Sheikh Zayed Road and only a short walk away from the metro station - every location in the city is easy to reach.",
    desccollapse: "Test Description Test Test offers luxury a cms equipped with a private free Wi-Fi. \nThe hotel offers you the opportunity to enjoy the Paris cultural scene with its close proximity to some of the main tourist attractions, such as Opéra Garnier, Montmartre, Georges Pompidou Center, Eiffel Tower and Louver Museum. \nThis is our guests' favorite part of Paris, according to independent reviews.",
    descroom: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
    img: ['100', '101', '102', '103', '104', '105', '106', '107', '108', '109', '110', '111'],
    headerimg: ['101'],
    rooms: {
        room: [
          {
            id: "1",
            name: 'Superior2 Double Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['54', '55', '56', '57'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
          {
            id: "2",
            name: 'Superior2 Twin Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['58', '59', '60', '61'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
          {
            id: "3",
            name: 'Executive2 Double Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['54', '55', '56', '57'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
          {
            id: "4",
            name: 'Executive2 Twin Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['58', '59', '60', '61'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
        ]
      }
    },
    {
      id: 3470,
      name: "Mudha Hotel",
      title:"Mudha_Hotel1_Downtown_Hargeisa",
      location: {
      country: "Somaliland - Coming Soon",
      city: "Downtown Hargeisa",
      addr: "Mudha Hoteldd Bldg, Gullallah Hargeisa, Somaliland. P.O.Box: 41519 ",
      subway_access: true,
      distance_from_center: 0.6,
      coordinates: {lat: "9.563203211601053",long: "44.07692756881448"}
      },
      phone: "+971-42512418",
      fax: "+971-42512418",
      mail: "info@mudhahotels.com",
      bed_sizes: ["king","queen","normal"],
      included: ["breakfast","dinner","all-in","none"],
      pricing: {
        bed: {
          king: 265,
          queen: 200,
          normal: 150
        },
      },
      rating: {cleanliness: 4,staff: 3,comfort: 4,service: 4},
      ratingnumber: "4.5",
      ratingnumberdesc: "246 ratings & 56 reviews",
      facilities: ["Restaurant","Breakfast","Room Service","Bar","Free Wifi","Accessible","Gym","Tea/coffee","City View","Free Parking"],
      desc: "Test Description Test Test features a restaurant, seasonal outdoor swimming pool, a bar and shared lounge in Marrakech. Featuring a garden, the riad is close to several noted attractions, around 200 m from Boucharouite Museum, 801 m from The Orientalist Museum of Marrakech and 0.7 mi from Le Jardin Secret. The accommodations offers a 24-hour front desk, airport transfers, room service and free WiFi.\nThe rooms at the riad come with a seating area. The units feature a private bathroom, slippers and bed linen.\nRiad Azzar offers a continental or à la carte breakfast.\nThe accommodations offers a terrace. The area is popular for cycling, and bike hire is available at Riad Azzar.\nPopular points of interest near the riad include Mouassine Museum, Bahia Palace and Djemaa El Fna. The nearest airport is Marrakech-Menara Airport, 3.1 mi from Riad Azzar.\nThis is our guests' favorite part of Marrakesh, according to independent reviews.",
      desccollapse: "Test Description Test Test offers luxury a cms equipped with a private free Wi-Fi. \nThe hotel offers you the opportunity to enjoy the Paris cultural scene with its close proximity to some of the main tourist attractions, such as Opéra Garnier, Montmartre, Georges Pompidou Center, Eiffel Tower and Louver Museum. \nThis is our guests' favorite part of Paris, according to independent reviews.",
      descroom: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
      img: ['200', '201', '202', '203', '204', '205', '206', '207', '208', '209', '210', '211'],
      headerimg: ['100'],
      rooms: {
        room: [
          {
            id: "1",
            name: 'Superior3 Double Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['58', '59', '60', '61'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
          {
            id: "2",
            name: 'Superior3 Twin Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['54', '55', '56', '57'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
          {
            id: "3",
            name: 'Executive3 Double Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['58', '59', '60', '61'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },
          {
            id: "4",
            name: 'Executive3 Twin Room',
            desc: 'Modern decor and comfort: queen-size bed, en-suite bathtub or shower, large desk, free unlimited WiFi, minibar, tea and coffee making facilities. Measuring 25 to 33 m², our Superior Room can accommodate up to 2 adults.',
            img: ['54', '55', '56', '57'],
            roomfacilities: [
              "x2",
              "From 29 m² / 312 sq ft",
              "Breakfast",
              "Room Service",
              "Free Wifi",
              "Flat Screen TV",
              "Air conditioning",
              "Attached bathroom"
            ],
            roomservices: ["Free toiletrie", "Bathrobe", "Bidet", "Toilet", "Bathtub or shower", "Towels", "Linens", "Tile/Marble floor", "Desk", "Sitting area", "Slippers", "Telephone", "Ironing facilities", "Satellite channels", "Tea/Coffee maker", "Iron", "Hairdryer", "Carpeted", "Electric kettle", "Wake-up service", "Alarm clock", "Wardrobe or closet", "Toilet paper", "Hand sanitizer",],
          },

        ]
      }

    },
  ]
}
