<template>
  <div class="fixed-top">
    <!-- <div class=" " id="navbar_top"> -->
    <div class="container-fluid mx-0 px-0">
      <div class="bg-header-primary py-1 text-center mx-0 px-0 border-header">
        <div class="row mx-0 px-0">

          <div class="col align-self-center">
            <div class="dropdown">
              <button class="btn btn-transparent text-white btn-sm  m-0 p-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="/flag_en.svg" alt="english flag" class="h-8 w-8" />
                <span class="ffont"> English</span>
                <svg class="svg-marg1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="20" height="20">
                  <path class="heroicon-ui" d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"></path>
                </svg>
              </button>
              <ul class="dropdown-menu dropdown-menu1   border dropdown-menu-start   " aria-labelledby="dropdownMenuButton1">
                <li>
                    <a class="dropdown-item" href="#">
                    <img src="/flag_en.svg" alt="english flag" class="h-8 w-8"/>
                    <span class="ffont"> English</span></a>
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    <img src="/flag_fr.svg" alt="french flag" class="h-8 w-8" />
                    <span class="ffont"> French</span></a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-5 align-self-center  ">
            <router-link to="/" class="navbar-brand  mb-0 pb-0" @click.native="scrollToTop">
              <img alt="Ominco Group LTD (UK)" class="svgcutom logo-custom" src="@/assets/logo-01.svg"/></router-link>
          </div>

          <div class="col align-self-center">
            <button type="button" class="btn btn-light shadow-sm btn-sm btn-overwrite"><b>BOOK NOW</b></button>
          </div>

        </div>
        </div>
    </div>

 <div class="navbar navbar-expand-sm bg-header-primary m-0 p-0  border-header ">
   <div class="container ">
    <ul class="   list-group-horizontal list-group list-unstyled mx-auto ">
        <li class="nav-item dropdown ">
    <router-link to="/" class="nav-link text-white   ffont fromLeft "  @click.native="scrollToTop"  >  <span> Home</span>
              <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="0" height="20">
            <path class="heroicon-ui  " d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z" ></path>
            </svg>
        </router-link>
        </li>


      <li class="nav-item dropdown  has-megamenu   ">
            <a class="nav-link text-white   ffont fromLeft  "   data-bs-toggle="dropdown">  <span> All Our Hotels </span>
              <svg   class="svg-marg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" width="20" height="20">
            <path class="heroicon-ui  " d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z" ></path>
            </svg>
        </a>

    <div class="dropdown-menu border-custom megamenu container  mx-auto my-auto  " role="menu">
<OurHotelsMegaMenu />
        </div>
      </li>

    </ul>
</div>
</div>
  </div>

</template>
<script>
import OurHotelsMegaMenu from "@/components/Header/MegaMenu/OurHotelsMegaMenu.vue";
export default {
  name: "Home",
  components: {
    OurHotelsMegaMenu
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    document.addEventListener("DOMContentLoaded", function () {
      window.addEventListener("scroll", function () {
        if (window.scrollY > 200) {
          document.getElementById("navbar_top").classList.add("fixed-top");
           } else {
          document.getElementById("navbar_top").classList.remove("fixed-top");
          // remove padding top from body
          document.body.style.paddingTop = "0";
        }
      });
    });
  document.addEventListener("DOMContentLoaded", function () {
      /////// Prevent closing from click inside dropdown
      document.querySelectorAll(".dropdown-menu").forEach(function (element) {
        element.addEventListener("click", function (e) {
          e.stopPropagation();
        });
      });
    });
  },
};
</script>

<style scoped lang="scss">
.fixed-top {
  top: -40px !important;
  transform: translateY(40px);
  transition: transform 0.09s;
}
.bg-header-primary {
  background: #0057b7;
}

@media all and (min-width: 992px) {
  .logo-custom {
    height: 100px;
  }
}
@media all and (max-width: 991px) {
  .logo-custom {
    height: 70px;
  }
}
.Language_title {
  color: rgb(255, 255, 255) !important;
  font-size: 0.75rem !important;
  font-weight: bold;
}
.w-8 {
  width: 1.1rem;
}
.h-8 {
  height: 1.3rem;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  margin-top:1px !important;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #0057b7;
  border: none;
  border-radius: 0rem !important;
}
.dropdown-menu1 {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 5rem;
  padding: 0.1rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem !important;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.ffont {
  font-size: 13px;
  font-weight: 550;
  cursor: pointer;
}

.ffont:hover {
  font-size: 13px;
  font-weight: 550;
  color: rgb(221, 219, 219) !important;
}

.border-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-overwrite {
  padding: 0.3rem 0.7rem;
  margin-left: 0.9375rem;
  color: #0057b7;
  height: auto;
  background: #fff;
  font-size: 13px;

  font-weight: 400;
  letter-spacing: 0.03125rem;
}
.btn-overwrite:hover {
  background: rgb(240, 237, 237);
  color: #083b72;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

a {
  display: inline-block;
}
a:after {
  display: block;
  content: "";

  transform: scaleX(0);
  transition: all 0.3s ease 0s;
}
a:hover:after {
  transform: scaleX(1);
}

a.fromLeft:after {
  transform-origin: 0% 50%;
  border-bottom: solid 1.5px rgb(221, 219, 219);
}


@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link{   }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0px; }
}

.nav-link {
  display: block;
  padding: .5rem .5rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.svg-marg1{
  margin-right:-6px;
  margin-left:0px
}
.svg-marg{
  margin-right:-5px;
  margin-left:-4px
}

.navbar .megamenu{ padding: 1rem; }
/* ============ desktop view ============ */
@media all and (min-width: 992px) {

	.navbar .has-megamenu{position:static!important;}
	.navbar .megamenu{left:0; right:0; width:50%; margin-top:0;  }

}
/* ============ desktop view .end// ============ */


/* ============ mobile view ============ */
@media(max-width: 991px){
	.navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
		overflow-y: auto;
	    max-height: 90vh;


	}
  .navbar .has-megamenu{position:static!important;}
  	.navbar .megamenu{left:0; right:0; width:100% !important; margin-top:0;  }
}
/* ============ mobile view .end// ============ */
</style>

